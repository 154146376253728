/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrderLineItemStatus {
    REGISTRATION_FEE = 'registration_fee',
    ADJUSTMENT = 'adjustment',
    FEE = 'fee',
    CREDIT = 'credit',
}
